<app-content-modal class="hide-overflow" [buttons]="buttons">
  <h1>{{ 'EMPLOYEE_PORTAL.REQUEST_SENT' | translate }}</h1>
  <div class="u-text-center">
    <img src="assets/imgs/ptrab/oficina-sombra.svg" />
  </div>
  <p>
    <b>{{ 'EMPLOYEE_PORTAL.IMPORTANT!' | translate }}</b>
  </p>
  <p>
    {{ 'EMPLOYEE_PORTAL.CONTACT_COORDINATOR' | translate }}
  </p>
</app-content-modal>
