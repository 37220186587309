import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationController } from '@app/ptrab/services/confirmation/confirmation.controller';
import { TwoFactorOperationCode } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { UserPhone } from '@app/ptrab/shared/models/user-phone';
import { ModalManager, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { getUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ptrab-phone-selection',
  templateUrl: './phone-selection.component.html',
  styleUrls: ['./phone-selection.component.scss']
})
export class PhoneSelectionComponent implements OnInit {
  phones!: UserPhone[];
  phoneEdit!: boolean;
  isPortugueseUser!: boolean;
  userDiscontinousFixed!: boolean;

  selectedPhoneId!: string;
  description!: string;
  reminderMessage!: string;
  isSinglePhone!: boolean;

  buttons: Buttons[] = [
    {
      text: 'EMPLOYEE_PORTAL.EDIT_ADD_NUMBER',
      type: 'link',
      enabled: true,
      onClick: () => this.registerNumber()
    },
    {
      text: 'EMPLOYEE_PORTAL.CONTINUE',
      type: 'primary',
      enabled: false,
      onClick: () => this.close()
    }
  ];

  private messages!: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private translateService: TranslateService,
    private modalManager: ModalManager,
    private confirmationCtrl: ConfirmationController,
    private navigationService: NavigationService
  ) {
    this.getTranslations();
  }

  ngOnInit() {
    this.phones = this.data.phones;
    this.phoneEdit = this.data.phoneEdit;
    this.isPortugueseUser = this.data.isPortugueseUser;
    this.userDiscontinousFixed = this.data.userDiscontinousFixed;

    this.isSinglePhone = this.phones && this.phones.length === 1;
    this.changeSecondaryButton();
    this.checkPhones();
  }

  changeSecondaryButton() {
    if (this.phoneEdit) {
      this.buttons[0].text = 'EMPLOYEE_PORTAL.NOT_HAVE_NUMBER';
    }

    if (this.isPortugueseUser) {
      this.buttons.shift();
    }
  }

  close() {
    this.modalManager.dismissMatModal(this.selectedPhoneId);
  }

  setSelectedPhone(phoneId: string) {
    this.selectedPhoneId = phoneId;
    this.buttons[this.isPortugueseUser ? 0 : 1].enabled = true;
  }

  async registerNumber() {
    if (!this.phoneEdit) {
      this.modalManager.updateCurrentModalView(true);

      const confirmed = await this.confirmationCtrl.confirmAction({
        title: this.messages['EMPLOYEE_PORTAL.MANAGE_PHONES.TITLE'],
        message: this.messages['EMPLOYEE_PORTAL.MANAGE_PHONES.DETAIL'],
        confirmButtonText: this.messages['EMPLOYEE_PORTAL.CONTINUE'],
        cancelButtonText: this.messages['EMPLOYEE_PORTAL.CANCEL']
      });

      this.modalManager.updateCurrentModalView();

      if (!confirmed) {
        return;
      }

      this.openRegisterPage();
      this.close();
    } else {
      if (this.userDiscontinousFixed) {
        this.modalManager.dismissMatModal(TwoFactorOperationCode.REQUEST_OTHER_PHONE_DF);
      } else {
        this.modalManager.dismissMatModal(TwoFactorOperationCode.REQUEST_OTHER_PHONE);
      }
    }
  }

  openRegisterPage() {
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: getUrlFromMap(PAGES.PERSONAL_DATA)
    });

    this.navigationService.navigate(navEvent);

    this.modalManager.dismissMatModal();
  }

  trackByItems(item: UserPhone) {
    return item;
  }

  private checkPhones() {
    this.reminderMessage = this.messages[`EMPLOYEE_PORTAL.ONLY_MOBILE`];

    if (this.isSinglePhone) {
      this.description = this.messages['EMPLOYEE_PORTAL.SINGLE_PHONE.MESSAGE'];
      this.setSelectedPhone(this.phones[0].id);
      return;
    }
    this.description = this.messages['EMPLOYEE_PORTAL.SELECT_NUMBER'];
  }

  private getTranslations() {
    this.translateService
      .get([
        'EMPLOYEE_PORTAL.CLOSE_SESSION_TITLE',
        'EMPLOYEE_PORTAL.ACCEPT',
        'EMPLOYEE_PORTAL.CANCEL',
        'EMPLOYEE_PORTAL.CLOSE_SESSION_DETAIL',
        'EMPLOYEE_PORTAL.SEND',
        'EMPLOYEE_PORTAL.MANAGE_PHONES.TITLE',
        'EMPLOYEE_PORTAL.MANAGE_PHONES.DETAIL',
        'EMPLOYEE_PORTAL.PHONE_ENDED',
        'EMPLOYEE_PORTAL.SELECT_NUMBER',
        'EMPLOYEE_PORTAL.ONLY_MOBILE',
        'EMPLOYEE_PORTAL.SINGLE_PHONE.MESSAGE',
        'EMPLOYEE_PORTAL.CONTINUE'
      ])
      .subscribe((messages) => (this.messages = messages));
  }
}
