import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { Logger, ModalManager, NetworkService, AlertService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, PagesAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { LoadingService } from '@app/services/loading/loading.service';
import { IProfileAvatarEvent } from '@app/services/profile/profile.avatar.service';
import { UserService } from '@app/services/user/user.service';
import { FileFetchHelper } from '@app/shared/helpers/file-fetch/file-fetch.helper';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { User } from '@app/shared/models/user/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent implements OnInit, OnDestroy {
  @Input() userInfo: MSafeAny;
  @Output() closeEvent = new EventEmitter<void>();

  private readonly logger: Logger = new Logger('Menu header component');
  private userInfoSubscription!: Subscription;

  constructor(
    private loadingService: LoadingService,
    private modalManager: ModalManager,
    private network: NetworkService,
    private analyticsService: AnalyticsService,
    private alertService: AlertService,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userInfoSubscription = new Subscription();
    this.subscribeUserInfoChanges();
  }

  ngOnDestroy() {
    this.userInfoSubscription.unsubscribe();
  }

  onClose() {
    this.closeEvent.emit();
  }

  subscribeUserInfoChanges() {
    this.userInfoSubscription.add(
      this.userService.userInfoChanges$.subscribe((userInfo) => {
        this.handleNewUserInfo(userInfo);
      })
    );
  }

  private handleNewUserInfo(userInfo: User) {
    if (!userInfo) {
      this.logger.warn('handleNewUserInfo: User Info is empty, aborting...');
      return;
    }

    this.userInfo = userInfo;
  }

  confirmAvatarEdit(profileAvatarEvent: IProfileAvatarEvent) {
    this.logger.debug('profileAvatarEvent', profileAvatarEvent);

    const file = profileAvatarEvent.file as File;
    const preview = profileAvatarEvent.preview as string;

    const title = 'PROFILE.EDIT_AVATAR.TITLE';
    const contents = ['PROFILE.EDIT_AVATAR.CONFIRM_MODAL_1', 'PROFILE.EDIT_AVATAR.CONFIRM_MODAL_2'];
    const buttons: Buttons[] = [
      {
        text: 'CANCEL',
        type: 'secondary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
          return this.network.redirectIfOffline();
        }
      },
      {
        text: 'PROFILE.EDIT_AVATAR.CONFIRM_BUTTON',
        type: 'primary',
        enabled: true,
        onClick: async () => {
          if (!this.network.hasConnection()) {
            this.modalManager.dismissMatModal();
            return this.network.redirectIfOffline();
          }

          this.loadingService.show();

          try {
            await this.userService.editAvatar(file).toPromise();
            this.userInfo = { ...this.userInfo, photo: preview };
            this.changePhotoOnStorage(file);
          } catch (error) {
            this.onErrorEditAvatar(error);
          } finally {
            this.analyticsService.sendEvent(CategoriesAnalytics.USER_MENU, {
              [ActionsAnalytics.CLICKACTION]: ActionsAnalytics.USER_PIC_EDITED
            });

            this.loadingService.hide();
            this.modalManager.dismissMatModal();
          }
          return null;
        }
      }
    ];

    this.modalManager.openMatModal(ConfirmationModalComponent, {
      data: { title, contents, buttons, pageAnalytics: PagesAnalytics.MODAL_CHANGE_AVATAR_IMAGE },
      panelClass: ['media-content-modal', 'base-modal--confirmation'],
      disableClose: true
    });
  }

  private async changePhotoOnStorage(file: File) {
    const b64 = await FileFetchHelper.getB64FromBlob(file);
    await this.userService.storeAvatar(b64);
    this.alertService.showSuccess(
      this.getMessage('PROFILE.SUCCESS_TITLE'),
      this.getMessage('PROFILE.EDIT_AVATAR.SUCCESS_BODY'),
      3000
    );
  }

  private onErrorEditAvatar(error) {
    this.logger.error(error);
    this.alertService.showError(
      this.getMessage('PROFILE.EDIT_AVATAR.ERROR_TITLE'),
      this.getMessage('PROFILE.EDIT_AVATAR.ERROR_BODY'),
      3000
    );
  }

  private getMessage(key: string): string {
    return this.translate.instant(key);
  }
}
