import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalManager, NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { getUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-ptrab-phone-registration',
  templateUrl: './phone-registration.component.html',
  styleUrls: ['./phone-registration.component.scss']
})
export class PhoneRegistrationComponent implements OnInit {
  isPortugueseUser!: boolean;
  email!: string;

  buttons: Buttons[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private modalManager: ModalManager,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.isPortugueseUser = this.data.isPortugueseUser;

    this.setButtons();
  }

  openRegisterPage() {
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: getUrlFromMap(PAGES.PERSONAL_DATA)
    });

    this.navigationService.navigate(navEvent);

    this.modalManager.dismissMatModal();
  }

  private setButtons() {
    this.buttons = [
      {
        text: this.isPortugueseUser ? 'EMPLOYEE_PORTAL.ACCEPT' : 'EMPLOYEE_PORTAL.REGISTER',
        type: 'primary',
        enabled: true,
        onClick: () => this.openRegisterPage()
      }
    ];
  }
}
