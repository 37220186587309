import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PhoneStatusTypes } from '@app/ptrab/shared/enums/phone-status-types';
import { Phone } from '@app/ptrab/shared/models/phone';
import { ModalManager } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { EmptySpaceValidator } from '@app/shared/validators/empty-space.validator';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-second-fa-phone-edition',
  templateUrl: './second-fa-phone-edition.component.html',
  styleUrls: ['./second-fa-phone-edition.component.scss']
})
export class SecondFaPhoneEditionComponent implements OnInit {
  phones!: Phone[];
  availablePhones!: Phone[];

  readonly PHONE_MAXLENGTH = 12;
  phoneMaxlengthAndroid!: number;
  form = new FormGroup({
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(this.PHONE_MAXLENGTH),
      Validators.pattern('^(?:\\(?\\+\\d{1,3}\\)?|0)?\\d{9}$'),
      EmptySpaceValidator.checkEmptySpaces
    ])
  });
  hasError!: boolean;

  selectedPhone!: Phone;

  buttons = [
    {
      text: 'EMPLOYEE_PORTAL.CANCEL',
      type: 'secondary',
      enabled: true,
      onClick: () => this.close(false)
    },
    {
      text: 'EMPLOYEE_PORTAL.ADD',
      type: 'primary',
      enabled: false,
      onClick: () => this.close(true)
    }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: MSafeAny, private modalManager: ModalManager) {}

  ngOnInit() {
    this.phones = this.data.phones;

    this.availablePhones = this.phones.filter((phone) => phone.show_to_edit);
    this.form.valueChanges.subscribe(() => {
      this.hasError = Boolean(this.form.get('phone')?.hasError('maxlength'));
      this.buttons[1].enabled = this.form.valid;
    });
  }

  isPhoneStatusPending(phone: Phone): boolean {
    return phone.status.status_code === PhoneStatusTypes.PENDING;
  }

  close(withValue: boolean) {
    if (withValue) {
      this.selectedPhone.phone = this.form.get('phone')?.value;
      this.modalManager.dismissMatModal(this.selectedPhone);
    } else {
      this.modalManager.dismissMatModal(null);
    }
  }

  setPhone(phone: Phone) {
    this.selectedPhone = phone;
  }

  trackByItems(item: Phone) {
    return item;
  }
}
